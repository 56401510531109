<template>
  <v-container>
    <v-card>
      <v-card-title>
        <span class="headline">Задача</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row ref="form">
            <v-col class="pt-0 pb-0" cols="4">
              <v-text-field
                ref="name"
                v-model="item.name"
                :rules="[() => !!item.name]"
                dense
                label="Название:"
              />
            </v-col>
            <v-col class="pt-0 pb-0" cols="4">
              <v-text-field
                ref="place_number"
                v-model.number="item.place_number"
                :rules="[() => !!item.place_number]"
                dense
                label="Основных мест:"
                type="number"
              />
            </v-col>
            <v-col class="pt-0 pb-0" cols="4">
              <v-text-field
                ref="additional_place"
                v-model.number="item.additional_place"
                :rules="[() => !!item.additional_place]"
                dense
                label="Доп. место:"
                type="number"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text :disabled="loading" @click="close">
          Отменить
        </v-btn>
        <v-btn color="primary" text :disabled="loading" @click="save">
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "Task",
  data() {
    return {
      singleImage: null,
      item: {
        id: null,
        description: null,
        todo_time: new Date(),
        todo: null,
        task_type: null,
        booking: null
      },
      loading: false
    };
  },
  validations: {
    item: {
      name: { required },
      place_number: { required },
      additional_place: { required },
      room_name: { required },
      room_number: { required },
      numbering: { required },
      description: { required }
    }
  },
  computed: {
    formTitle() {
      return this.$route.params.id ? "Редактировать" : "Создать";
    }
  },
  created() {
    if (this.$route.params.id) {
      window.axios
        .get("/api/room/category/detail/" + this.$route.params.id + "/")
        .then(response => {
          this.item = response.data;
        });
    }
  },
  methods: {
    async save() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        Object.keys(this.item).forEach(f => {
          if (this.$refs[f]) {
            this.$refs[f].validate(true);
          }
        });
        return;
      }
      for (var i = 0; i < this.select.length; i++) {
        this.item.tags.push({ text: this.select[i] });
      }
      this.loading = true;
      if (this.$route.params.id) {
        window.axios
          .put(
            "/api/room/category/update/" + this.$route.params.id + "/",
            this.item
          )
          .then(response => {
            this.loading = false;
            this.close();
          });
      } else {
        this.loading = true;
        await window.axios
          .post("/api/room/category/create/", this.item)
          .then(response => {
            this.loading = false;
            var formData = new FormData();
            formData.append("category", response.data.id);
            formData.append("is_main", true);
            formData.append(
              "image",
              this.singleImage,
              this.singleImage.fileName
            );
            window.axios
              .post("/api/room/category/images/", formData, {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              })
              .then(resp => {
                this.loading = false;
                var formData = new FormData();
                formData.append("category", response.data.id);
                for (var i = 0; i < this.multiImages.length; i++) {
                  formData.append(
                    "images",
                    this.multiImages[i],
                    this.multiImages[i].fileName
                  );
                }
                window.axios
                  .post("/api/room/upload/category/images/", formData, {
                    headers: {
                      "Content-Type": "multipart/form-data"
                    }
                  })
                  .then(rss => {
                    this.loading = false;
                  });
              });
            this.close();
          });
      }
    },
    close() {
      this.$router.push("/categories");
      this.loading = false;
    }
  }
};
</script>

<style scoped></style>
